<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

import { mapState, mapActions, mapMutations } from "vuex"
import Ripple from 'vue-ripple-directive'

export default {
    directives: {Ripple, },
    components:{
    },
    async created() {
        await this.getInitialData()
    },
    computed: {
        ...mapState('start', ['hotels']),
    },
    methods:{
        ...mapActions('fivesClubCatalogs', ['getInitialContentRentalPool']),
        ...mapMutations('fivesClubCatalogs',['setCondos','setSubCondos','setRentalPool']),

        ...mapMutations('start',['setHotels']),
        async getInitialData(){
            const { condos, subCondos,  hotels, rentalPool } = await this.getInitialContentRentalPool();
            this.setCondos(condos)
            this.setSubCondos(subCondos)
            this.setRentalPool(rentalPool)
            if(this.hotels.length === 0){
                this.setHotels(hotels)
            }
        },
    }
};
</script>

<style scoped>
.head-btn{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
.head-title{
    font-size: 1.5rem;
    color: #000;
}
</style>